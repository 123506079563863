<template>
    <div style="width:100%">
        <div class="prb-result-buttons">
            <div class="spacer" />
            <el-button size="medium" @click="export_to_excel">
                export to excel
            </el-button>
        </div>
        <webix-ui :config='report_grid' v-model='report_data' ref="webixTbl" v-if="report_grid" />
    </div>
</template>

<script>

import * as webix from "webix"
import '@/directives/webix/'
import $ from 'jquery'
export default {
    props: {
        fields: {
            type: Array,
            required: true
        }
    },

    data(){
        return {
            report_settings : {},
            report_data     : [],
            report_grid     : undefined,
            grid_id         : "ReportGrid" + Date.now(),
        }
    },

    methods: {
        load_grid(report_data, report_settings){
            this.report_data = report_data
            this.report_settings = report_settings

            if (this.$refs.webixTbl && this.$refs.webixTbl.webixId)
                this.$refs.webixTbl.webixId.destructor();
            this.get_table_config();
        },

        get_table_config(){
            let $this = this;
            let columns = $this.get_grid_columns()

            let table = {
                view         : ($this.report_settings.groupby.length) ? "treetable" : "datatable",
                sort         : "multi",
                select       : "row",
                resizeColumn : true,
                dragColumn   : false,
                columns      : columns,
                id           : $this.grid_id,
                scroll       : true,
                css          : "tl-wbx",
                on: {
                    onAfterLoad: function(){
                        let height = $(window).height() - 205
                        this.define('height', height);
                        this.resize();

                        if ($this.report_settings.sortby.length) {
                            let sorts = [];
                            $this.report_settings.sortby.forEach(sort => {
                                sorts.push({
                                    by: sort.field,
                                    dir: sort.dir
                                });
                            })
                            this.sort(sorts)

                            $this.report_settings.sortby.forEach(sort => this.markSorting(sort.field, sort.dir, true))
                        }

                        if ($this.report_settings.groupby.length) {
                            var elements = [];
                            $this.report_settings.groupby.forEach(group => {
                                elements.unshift(group.field)
                            })
                            var count = elements.length;
                            // apply several levels of grouping
                            for (var i = 0; i < count; i++) {
                                var map = provide_map(elements);
                                this.group({
                                    by:function(obj){
                                        var group_tag = "";
                                        elements.forEach(function(el){
                                            group_tag += obj[el] || "";
                                            group_tag += "-"
                                        });
                                        return group_tag;
                                    },
                                    map:map
                                }, 0);// only top level of data
                                // remove used tag before grouping the next level
                                elements.shift();
                            }

                            this.openAll();
                        }
                    }
                }
            }

            $this.report_grid = table
        },

        

        get_grid_columns(){
            let columns = []

            this.report_settings.columns.forEach((column, index) => {
                let field_data = this.fields.find(x => x.id === column.field)
                if (!field_data)
                    return;

                let col = {
                    id          : column.field,
                    header      : [{text:"<div>"+field_data.title+"</div>", css:"ta_c multiline", height:60}],
                    // minWidth : (field_data.width) ? field_data.width : 100,
                    colname     : field_data.title,
                    css         : {}
                }

                if (field_data.adjust)
                    col.adjust = "data";
                else
                    col.minWidth = (field_data.width) ? field_data.width : 100;

                // if (field_data.status === 'cancel')
                //     col.css['background-color'] = '#Ffecef';
                // else if (field_data.status === 'halfready')
                //     col.css['background-color'] = '#Fff4ec';
                // else if (field_data.status !== 'ready')
                //     col.css['background-color'] = '#FFF9DD !important';


                if (['position_type','asset_class','rating'].includes(field_data.id) && field_data.options) {
                    col.options = field_data.options
                }

                if (this.report_settings.groupby.length && index == 0) {
                    col.template = function(obj, common){
                        return common.icon(obj,common) + (obj.value || obj[column.field]);
                    }
                    col.adjust = "data"
                }
                else if (index == 0) {
                    col.fillspace = 1;
                }

                if (field_data.type && field_data.type === 'datetime') {
                    col.format = webix.Date.dateToStr("%Y-%m-%d")
                    col.sort = 'date'
                }
                else if (field_data.type && ['int', 'float'].includes(field_data.type)) {
                    let digits = 0
                    if (field_data.decimals) digits = field_data.decimals;
                    else if (field_data.type === 'float') digits = 4;
                    col.format = webix.Number.numToStr({
                        groupDelimiter   :" ",
                        groupSize        :3,
                        decimalDelimiter :".",
                        decimalSize      :digits
                    })
                    col.sort = 'int'
                    col.css['text-align'] = 'right'
                }

                columns.push(col);
            })

            return columns
        },

        export_to_excel(){
            let filename = 'Report'
            let columns = []
            let tableColumns = this.get_grid_columns()

            tableColumns.forEach(tc => {
                let col = {
                    id     : tc.id,
                    header : tc.colname,
                    template:function(obj){
                        let val = obj[tc.id]
                        if (!val) {
                            return ''
                        }
                        else if (tc.options && tc.options.length) {
                            let option = tc.options.find(x => x.id == val)
                            if (option)
                                return option.value;
                            else
                                return val;
                        }
                        else {
                            return val
                        }
                    },
                }

                columns.push(col)
            })

            webix.toExcel($$(this.grid_id), {
                filterHTML : true,
                filename   : filename,
                columns    : columns
            });
        }
    }
}

function provide_map(el){
    var obj = {};
    obj.value = [el[0]];
    for ( var i = 1; i < el.length; i++ ){
        var key = el[i];
        obj[key] = [key];
    };
    return obj
};
</script>
