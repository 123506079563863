<template>
    <div v-loading="report_loading" class="reporting-pnl-page">
        <ReportGrid ref="ReportGrid" :fields="fields" />
        <!-- <json-viewer :value="report_data" :expand-depth=3></json-viewer> -->
    </div>
</template>

<script>
import ReportGrid from '@/views/Reporting/ReportComponents/ReportGrid'
import Fields from '@/views/Reporting/Reports/PlReport/Fields.json'
export default {
    components: { ReportGrid },
    props: {
        report_settings: {
            type: Object,
            required: true
        }
    },

    data(){
        return {
            report_data: [],
            report_loading:false,
            fields: []
        }
    },

    methods: {
        load_report(){
            this.report_data = []

            let clientid_field = this.report_settings.filters.find(x => x.field === 'client_id')
            if (!clientid_field)
                return;
            if (!clientid_field.value)
                return;

            let fields = {}
            this.report_settings.columns.forEach(item => {
                fields[item.field] = 1
            })
            this.report_settings.sortby.forEach(item => {
                fields[item.field] = 1
            })
            this.report_settings.groupby.forEach(item => {
                fields[item.field] = 1
            })

            this.get_fields()

            let params = {
                filter   : this.report_settings.filters,
                fields   : Object.keys(fields),
                clientid : clientid_field.value
            }
            this.report_loading = true
            this.$store.dispatch('reporting/getPnl', params).then((response) => {
                this.report_data = response._items
                this.report_loading = false
                this.$refs.ReportGrid.load_grid(this.report_data, this.report_settings)
            })
        },

        get_fields(){
            this.fields = JSON.parse(JSON.stringify(Fields));
            this.fields.forEach(async (field) => {
                if (field.id === 'position_type') {
                    field.options = [
                        { id: 'long', value: 'Long' },
                        { id: 'short', value: 'Short' },
                        { id: 'flat', value: 'Flat' },
                    ]
                }
                else if (field.id === 'asset_class') {
                    field.options = [
                        { id: 'cash', value: 'Currency' },
                        { id: 'bond', value: 'Fixed Income' },
                        { id: 'equity', value: 'Equity' },
                        { id: 'future', value: 'Future' },
                        { id: 'option', value: 'Option' },
                    ]
                }
                else if (field.id === 'rating') {
                    field.options = [
                        { id: '21', value: 'AAA' },
                        { id: '20', value: 'AA+' },
                        { id: '19', value: 'AA' },
                        { id: '18', value: 'AA-' },
                        { id: '17', value: 'A+' },
                        { id: '16', value: 'A' },
                        { id: '15', value: 'A-' },
                        { id: '14', value: 'BBB+' },
                        { id: '13', value: 'BBB' },
                        { id: '12', value: 'BBB-' },
                        { id: '11', value: 'BB+' },
                        { id: '10', value: 'BB' },
                        { id: '9', value: 'BB-' },
                        { id: '8', value: 'B+' },
                        { id: '7', value: 'B' },
                        { id: '6', value: 'B-' },
                        { id: '5', value: 'CCC+' },
                        { id: '4', value: 'CCC' },
                        { id: '3', value: 'CCC-' },
                        { id: '2', value: 'CC' },
                        { id: '1', value: 'C' },
                        { id: '0', value: 'D' },
                    ]
                }
            })
        },
    },

    mounted(){
        this.$nextTick(() => {
            this.load_report()
        })
    }
}
</script>
